// https://dropboxdesignstandards.com/foundation/Color/intro
// Define colors
$dropbox-blue: #0061FE;
$dropbox-blue-light: #3984FF;
$coconut: #F7F5F2;
$graphite: #1E1919;
$borderRadius: 3px;
$primary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Export variables for global use
:export {
    dropboxBlue: $dropbox-blue;
    dropboxBlueLight: $dropbox-blue-light;
    coconut: $coconut;
    graphite: $graphite;
    borderRadius: $borderRadius;
    primaryFont: $primary-font
}
