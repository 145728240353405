@import '../colors.module';

.panel {
    background-color: white;
    padding: 15px 20px;
    width: 100%;
    border-radius: $borderRadius;
}

.info {

}

.row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
}


.title {
    color: $dropbox-blue;
    font-weight: bold;
}

.value {
    color: grey
}


.radioLabel {
    margin-left: 10px !important;
    margin-right: 0 !important;
}

.radio {
    padding: 3px !important;
}
