
.container {
    width: 100%;
}

.left {
}

.right {
    width: calc(100% - 170px);
}

.title {
    color: grey
}
