/* Your styles goes here. */
@import "../colors.module";

.main {
  width: 1024px;
  margin: auto;
  color: $graphite;
  padding-bottom: 100px;
}

.calculator {
  width: 58%;
  margin: 10px 0;
}

.offer {
  width: 35%;
  margin: 10px 0;
}

.agreement {
  font-family: -apple-system, Times;
  text-align: center;
  font-size: 18px;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }

  ul li {
    list-style: none;
    text-align: left;
  }

  ul li::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: black;
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 50%;
  }

  .title {
    text-decoration: underline;
    font-weight: bold;
  }

  .description {
    font-size: 20px;
  }


}


