@import '../colors.module';

.uploadModalButton {
  margin: 10px auto !important;
  width: 200px;
}

.offerContainer {
  background-color: $dropbox-blue-light;
  border-radius: $borderRadius;
  color: white;
  padding: 10px 20px 30px;

  .repaymentContainer {

    padding: 10px;
    background-color: $coconut;
    border-radius: $borderRadius;
    margin: 12px 0;

    .repaymentTitle {
      color: gray;
      font-weight: bold;
      font-size: 12px;
    }

    .repaymentNumber {
      color: $dropbox-blue;
      font-size: 35px;
      font-weight: bold;
    }

  }


  .rowContainer {
    background-color: $dropbox-blue;
    border-radius: $borderRadius;
    padding: 10px 20px;
    box-sizing: border-box;

    .rowItem {
      padding: 5px 0;
    }
  }




  .uploadPanel {
    background-color: $coconut;
    border-radius: $borderRadius;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    color: $graphite;

    .uploadButton {
      margin: auto;
    }

    .fileRowContainer {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .fileType {
      width: 35%;
      min-width: 35%;
    }

    .fileName {
      width: 65%;
      display: flex;
      align-items: center;

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }

  }


}

.offerContainer > div {
  width: 100%;
  text-align: center;

}


.offerContainer > div > h3 {
  margin: 0;
}


.value {
  font-weight: bold;
}

